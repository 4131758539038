<template>
  <div class="container w-full mx-auto px-4 md:px-0">
    <div class="mb-10 text-center w-full">
      <div class="w-32 mx-auto mb-5">
        <img
          class="w-full max-h-full rounded"
          :src="avatarLink(user.wmid)"
          :alt="user.wmid"
        />
      </div>

      <h5 class="uppercase text-2xl text-gray-700 dark:text-white">
        <span class="font-bold">
          {{ user.nickname || user.wmid }}
        </span>

        <div class="text-gray-400 text-lg" v-if="user.nickname">
          {{ user.wmid }}
        </div>
      </h5>
    </div>

    <div class="md:w-1/2 mx-auto">
      <div class="flex space-x-4">
        <div class="flex-1 text-center">
          <router-link
            exact-active-class="bg-gray-100"
            :to="$i18nRoute({ name: 'subscriptions' })"
            class="rounded hover:bg-gray-200 block border dark:border-gray-700 py-5"
          >
            <div class="">
              <heart-icon size="1.5x" class="mb-2 block mx-auto"></heart-icon>
              <span class="">
                {{
                  $tc("shared.subscriptions_count", user.subscriptions_count)
                }}
              </span>
            </div>
          </router-link>
        </div>
        <div class="flex-1 text-center">
          <router-link
            exact-active-class="bg-gray-100"
            :to="$i18nRoute({ name: 'my-feedbacks' })"
            class="rounded hover:bg-gray-200 block border dark:border-gray-700 py-5"
          >
            <div class="">
              <message-circle-icon
                size="1.5x"
                class="mb-2 block mx-auto"
              ></message-circle-icon>
              <span class="">
                {{ $tc("shared.feedbacks_count", user.feedbacks_count) }}
              </span>
            </div>
          </router-link>
        </div>
        <div class="flex-1 text-center">
          <router-link
            exact-active-class="bg-gray-100"
            :to="$i18nRoute({ name: 'comparisons' })"
            class="rounded hover:bg-gray-200 block border dark:border-gray-700 py-5"
          >
            <div class="">
              <copy-icon size="1.5x" class="mb-2 block mx-auto"></copy-icon>
              <span class="">
                {{ $tc("shared.comparisons_count", user.comparisons_count) }}
              </span>
            </div>
          </router-link>
        </div>
        <div class="flex-1 text-center">
          <router-link
            exact-active-class="bg-gray-100"
            :to="$i18nRoute({ name: 'ownerships' })"
            class="rounded hover:bg-gray-200 block border dark:border-gray-700 py-5"
          >
            <div class="">
              <globe-icon size="1.5x" class="mb-2 block mx-auto"></globe-icon>
              <span class="">
                {{ $tc("shared.ownerships_count", user.ownerships_count) }}
              </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  MessageCircleIcon,
  HeartIcon,
  CopyIcon,
  GlobeIcon,
} from "vue-feather-icons";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: { MessageCircleIcon, HeartIcon, CopyIcon, GlobeIcon },
  created() {},
  watch: {},
  computed: mapState({
    user: (state) => state.auth.user,
  }),
  methods: {
    avatarLink(wmid) {
      return (
        "https://events.web.money/open/avatar.aspx?t=url&s=normal&w=" + wmid
      );
    },
  },
};
</script>
